import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { client, popup as query, useCaseQuery } from '../Hooks/useSanityFetch';
import useSanityFetch from '../Hooks/useSanityFetch';

const BlockContent = require('@sanity/block-content-to-react');
const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

const Container = styled.div`
  padding: 2rem;
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 100vh;
  z-index: 999;
  top: 0;
  /* background: ${(props) => (props.component ? 'unset' : ' #00000020')}; */
  /* position: ${(props) => (props.component ? 'unset' : 'fixed')}; */
  .show-me {
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
  }
`;

const Wrapper = styled.div`
  justify-self: center;
  width: 100%;
  min-height: 700px;
  background: #fff;
  display: grid;
  position: relative;
  grid-template-rows: 0fr 1fr;
  max-width: 1000px;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 8px;

  i {
    justify-self: flex-start;
    font-size: 2.3rem;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .popup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    max-height: 456px;
  }
  .popup-content {
    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    text-align: left;
    flex-grow: 1;
    margin-top: 3rem;

    color: gray;
    &-container {
      display: grid;
      gap: 1rem;
      justify-items: flex-start;
      .block-content {
        display: grid;
        gap: 0.5rem;
        img {
          max-width: 100%;
        }
      }
    }
    &-title {
      font-weight: 600;
    }
    &-img {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }

    img {
      display: inline-block;
    }
  }
  .popup-buttons {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    justify-content: space-between;

    gap: 2rem;
    margin-top: 1rem;
    button {
      background: #fff;
      border: solid 1px black;
      height: 40px;
      padding: 0 2rem;
      border-radius: 4px;
      cursor: pointer;
      box-shadow: 2px 3px 9px -2px rgba(0, 0, 0, 0.17);
      -webkit-box-shadow: 2px 3px 9px -2px rgba(0, 0, 0, 0.17);
      -moz-box-shadow: 2px 3px 9px -2px rgba(0, 0, 0, 0.17);
    }
    .popup-next {
      background: rgb(33, 149, 243);
      color: #fff;
      border: solid 0px black;
    }
  }
  .popup-control {
    display: grid;
    grid-template-columns: ${(props) => props.gridCloumn};
    align-items: center;
    margin-top: 1.5rem;
    gap: 2rem;
    background: #80808010;
    border-radius: 8px;
    padding: 2rem;
    &-column {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      justify-items: center;
      align-items: flex-start;
      justify-content: space-between;
      gap: 2rem;
    }

    .circle-container:nth-child(1) {
      display: none;
    }

    #popup-circle {
      display: grid;
      place-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transition: 0.3s;
      cursor: pointer;
      font-weight: 700;
    }
    .popup-line {
      width: 100%;
      height: 2px;
      background: gray;
      &-dot {
        background: transparent;
        border: none;
        border-top: 2px dotted gray;
      }
    }
    .circle-container {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: 2rem;
      width: 100%;
      height: 100%;
    }
  }
  .control-active {
    background: rgb(33, 149, 243);
    color: #fff;
  }
  .control-disable {
    box-shadow: 2px 3px 9px 0px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 2px 3px 9px 0px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 2px 3px 9px 0px rgba(0, 0, 0, 0.17);
  }
`;

const NewPopup = () => {
  const [data, loading, error] = useSanityFetch({ id: '301' });
  const [popup, setPopup] = useState(null);
  const [focus, setFocus] = useState(0);
  const [activeColor, setActiveColor] = useState('');
  const [history, setHistory] = useState([]);

  React.useEffect(() => {
    if (data) {
      setPopup(data);
    }
  }, [loading]);

  useEffect(() => {
    popup?.content?.forEach((x, i) => {
      if (focus === i) {
        setActiveColor(popup.activeColor);
      }
    });

    // if (component) {
    //   if (focus === popup?.content?.length - 1) {
    //     setHideButton(true);
    //     return;
    //   }
    //   setHideButton(false);
    // }
  }, [focus]);

  useEffect(() => {
    setFocus(0);
    setActiveColor(popup?.activeColor);
    // if (!popupPrevious) {
    //   setPopupPrevious(popup);
    // }
  }, [popup]);

  const controlHandler = (num) => {
    if (focus + num === -1) {
      return setFocus(0);
    }
    if (focus + num === popup?.content?.length) {
      return setFocus(popup?.content?.length - 1);
    }
    setFocus(focus + num);
  };

  const swipeHandler = async (id) => {
    let x = await client.fetch(query, {});
    let y = await client.fetch(useCaseQuery, {});
    // If one of them fails, try another query
    return (
      x?.find((t) => t.id.current === id) || y?.find((t) => t.id.current === id)
    );
  };

  const historyHandler = async () => {
    // fetch by using swipeHandler
    // get history id in history
    // then shift the id in history
    // then fetch the data
    // then set the data to popup
    const x = await swipeHandler(history[0]);
    setPopup(x);
    setHistory(history.slice(1));
  };

  if (!popup || !data) return '';
  // recommendationPopup
  // setRecPopupActive

  return (
    <Container>
      <Wrapper gridCloumn={focus === 0 ? '1fr 1fr' : 'unset'}>
        <i
          onClick={historyHandler}
          className='far fa-arrow-alt-circle-left'
          style={{
            opacity: history.length ? 1 : 0,
            pointerEvents: history.length ? 'all' : 'none',
          }}
        ></i>
        <div className='popup-control'>
          <div>
            {popup?.content?.map(
              (x, i) => i === 0 && <div>{<strong>{x.stepName}</strong>}</div>
            )}
          </div>

          <div className='popup-control-column'>
            {popup?.content?.map((x, i) => (
              <div
                key={i}
                style={{
                  gridTemplateColumns: i === 0 ? 'auto 1fr' : 'auto auto 1fr',
                }}
                className='circle-container'
                disableQuestion={focus > 0 ? 'disable' : ''}
              >
                <div
                  id='popup-circle'
                  onClick={() => setFocus(i)}
                  className={i === focus ? 'control-active' : 'control-disable'}
                  style={{
                    display: i === 0 ? 'none' : 'grid',
                    color:
                      i - 1 < focus ? popup.activeColor : popup.activeColor,
                    backgroundColor:
                      i - 1 < focus ? popup.activeColor : popup.passiveColorBg,
                  }}
                >
                  {i}
                </div>

                <p
                  style={{
                    color: i > focus ? 'gray' : popup.activeColor,
                  }}
                  className='popup-stepName'
                >
                  {x.stepName}
                </p>
                {/* {i + 999 !== popup?.content?.length && (
                  <div
                    className={`popup-line ${i > focus && 'popup-line-dot'}`}
                    style={{
                      backgroundColor:
                        i - 1 < focus ? activeColor : x.passiveColorBg,
                    }}
                  ></div>
                )} */}
              </div>
            ))}
          </div>
        </div>
        <div className='popup'>
          <div className='popup-content'>
            {popup?.content?.map((x, i) => (
              <div
                key={i}
                style={{ display: i === focus ? 'grid' : 'none' }}
                className='popup-content-container'
              >
                {/* <p className='popup-content-title'>{x.title}</p> */}
                <BlockContent
                  className='block-content'
                  blocks={x.content}
                  serializers={serializers}
                />
                {/* {x?.image && (
                  <img
                    src={x?.image?.asset?.url}
                    alt='img'
                    className='popup-content-img'
                  />
                )} */}
              </div>
            ))}
          </div>
        </div>
        <div className='popup-buttons'>
          <div className='show-me'>
            <button
              style={{ display: focus === 0 ? 'none' : 'block' }}
              onClick={() => controlHandler(-1)}
            >
              {popup.prevButton}
            </button>
            {popup?.content?.find((x, i) => i === focus)?.showMeHowRef && (
              <button
                onClick={async () => {
                  const id = popup?.content?.find((x, i) => i === focus);

                  setHistory((prev) => [...prev, popup?.id?.current]);
                  const content = await swipeHandler(
                    id?.showMeHowRef?.id?.current
                  );
                  setPopup(content);
                }}
                // style={{ opacity: focus === 0 ? 0 : 1 }}
              >
                {popup?.content?.find((x, i) => i === focus)?.showMeHowText}
              </button>
            )}
          </div>
          <button
            style={{ background: popup.activeColor }}
            className='popup-next'
            onClick={() => {
              controlHandler(+1);
            }}
          >
            {popup?.content?.length - 1 === focus
              ? popup.endButton
              : popup.nextButton}
          </button>
          {/* <button onClick={() => dispatch(setRecPopupActive(false))}>
              {popup.closeButton}
            </button> */}
        </div>
      </Wrapper>
    </Container>
  );
};

export default NewPopup;
