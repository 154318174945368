import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  alreadyConditionedMulti,
  pushQuestion,
  pushTags,
  pushUserState,
  setFirstTimePush,
  updateUserState,
} from '../Redux/quizSlice';
const CardCondition = ({ type, obj }) => {
  const [conditionCard, setConditionCard] = useState([]);
  const [flipCards, setFlipCards] = useState([]);
  const [riskCards, setRiskCards] = useState([]);
  const [popups, setPopups] = useState([]);
  const [showBreakDown, setShowBreakDown] = useState(false);
  const [showRiskAssessment, setShowRiskAssessment] = useState(false);
  const state = useSelector((state) => state.quiz);
  const breakDown = state?.defaultJson?.stats?.tabMenus[0];
  const riskAssesment = state.defaultJson.riskAssesment;
  const ipopups = state?.ipopups;
  const dispatch = useDispatch();
  const form = state.userState;
  const questionsState = state.questions;
  let firstQuestion = true;

  const multiSelectTagHandler = () => {
    if (type !== 'multi') return;
    Object.entries(form).forEach(([a, b]) => {
      if (form[a].length === 0) return;
      if (typeof b === 'object') {
        const q = questionsState
          .find((question) => question.stateName === a)
          .options.map((option) => {
            if (
              b.find((xy) => xy === option.text) &&
              !state.alreadyConditionedMulti.includes(option._key)
            ) {
              // Push multi question id once pushed as conditioned tag
              dispatch(alreadyConditionedMulti(option._key));
              return option;
            }
          })
          .map((x) => {
            if (x === undefined || x?.conditionList === null) return;
            dispatch(pushTags(x?.conditionList?.map((x) => x.name)));
          });
      } else {
        // // Register none multi answers
        // const foundQuestion = questionsState.find(
        //   (question) => question.stateName === a
        // );
        // if (!foundQuestion) return;
        // const foundOption = foundQuestion?.options?.find(
        //   (option) => option?.text === b
        // );
        // if (!foundOption) return;
        // dispatch(pushTags(foundOption?.conditionList?.map((x) => x?.name)));
        // // dispatch(pushTags(foundOption?.conditionList?.map((x) => x?.name)));
      }
    });
  };
  const cardHander = () => {
    const cards = state.cards
      .filter((card) => card.section === type)
      .map((card) => {
        // Show without without condidition
        if (
          card.conditionedTagsMissing === null &&
          card.conditionedTagsExists === null
        ) {
          setConditionCard((prevArray) => [...prevArray, card]);
          return card;
        }
        // Card has only existing conditions
        if (
          card.conditionedTagsMissing === null &&
          card.conditionedTagsExists?.every((tag) =>
            state.tags.includes(tag.name)
          )
        ) {
          setConditionCard((prevArray) => [...prevArray, card]);
          return card;
        }

        // Card has only missing conditions
        if (
          card.conditionedTagsExists === null &&
          card.conditionedTagsMissing?.every((tag) =>
            state.tags.includes(tag.name)
          )
        ) {
          setConditionCard((prevArray) => [...prevArray, card]);
          return card;
        }
        // Show with conditions filter both
        if (
          card.conditionedTagsExists?.every((tag) =>
            state.tags.includes(tag.name)
          ) &&
          card.conditionedTagsMissing?.every(
            (tag) => !state.tags.includes(tag.name)
          )
        ) {
          setConditionCard((prevArray) => [...prevArray, card]);

          return card;
        }
      });
  };

  const flipCardHandler = () => {
    if (type !== 'flipcard') return;
    const cards = state.flipCards.map((card) => {
      // Show without without condidition
      if (
        card.conditionedTagsMissing === null &&
        card.conditionedTagsExists === null
      ) {
        setFlipCards((prevArray) => [...prevArray, card]);
        return card;
      }
      // Card has only existing conditions
      if (
        card.conditionedTagsMissing === null &&
        card.conditionedTagsExists?.every((tag) =>
          state.tags.includes(tag.name)
        )
      ) {
        setFlipCards((prevArray) => [...prevArray, card]);
        return card;
      }

      // Card has only missing conditions
      if (
        card.conditionedTagsExists === null &&
        card.conditionedTagsMissing?.every((tag) =>
          state.tags.includes(tag.name)
        )
      ) {
        setFlipCards((prevArray) => [...prevArray, card]);
        return card;
      }
      // Show with conditions filter both
      if (
        card.conditionedTagsExists?.every((tag) =>
          state.tags.includes(tag.name)
        ) &&
        card.conditionedTagsMissing?.every(
          (tag) => !state.tags.includes(tag.name)
        )
      ) {
        setFlipCards((prevArray) => [...prevArray, card]);

        return card;
      }
    });
  };

  const riskCardHandler = () => {
    if (type !== 'risk') return;
    const cards = state.riskCards.map((card) => {
      // Show without without condidition
      if (
        card.conditionedTagsMissing === null &&
        card.conditionedTagsExists === null
      ) {
        setRiskCards((prevArray) => [...prevArray, card]);
        return card;
      }
      // Card has only existing conditions
      if (
        card.conditionedTagsMissing === null &&
        card.conditionedTagsExists?.every((tag) =>
          state.tags.includes(tag.name)
        )
      ) {
        setRiskCards((prevArray) => [...prevArray, card]);
        return card;
      }

      // Card has only missing conditions
      if (
        card.conditionedTagsExists === null &&
        card.conditionedTagsMissing?.every((tag) =>
          state.tags.includes(tag.name)
        )
      ) {
        setRiskCards((prevArray) => [...prevArray, card]);
        return card;
      }
      // Show with conditions filter both
      if (
        card.conditionedTagsExists?.every((tag) =>
          state.tags.includes(tag.name)
        ) &&
        card.conditionedTagsMissing?.every(
          (tag) => !state.tags.includes(tag.name)
        )
      ) {
        setRiskCards((prevArray) => [...prevArray, card]);

        return card;
      }
    });
  };

  const ipopupHandler = () => {
    if (type !== 'ipopup') return;
    const cards = ipopups.map((card) => {
      // Show without without condidition
      if (
        card.conditionedTagsMissing === null &&
        card.conditionedTagsExists === null
      ) {
        setPopups((prevArray) => [...prevArray, card]);
        return card;
      }
      // Card has only existing conditions
      if (
        card.conditionedTagsMissing === null &&
        card.conditionedTagsExists?.every((tag) =>
          state.tags.includes(tag.name)
        )
      ) {
        setPopups((prevArray) => [...prevArray, card]);
        return card;
      }

      // Card has only missing conditions
      if (
        card.conditionedTagsExists === null &&
        card.conditionedTagsMissing?.every((tag) =>
          state.tags.includes(tag.name)
        )
      ) {
        setPopups((prevArray) => [...prevArray, card]);
        return card;
      }
      // Show with conditions filter both
      if (
        card.conditionedTagsExists?.every((tag) =>
          state.tags.includes(tag.name)
        ) &&
        card.conditionedTagsMissing?.every(
          (tag) => !state.tags.includes(tag.name)
        )
      ) {
        setPopups((prevArray) => [...prevArray, card]);

        return card;
      }
    });
  };

  const breakDownHandler = ({ object, stateHandler, showType }) => {
    if (type !== showType) return;

    // Show without without condidition
    if (
      object.conditionedTagsMissing === null &&
      object.conditionedTagsExists === null
    ) {
      stateHandler(true);
      return object;
    }
    // Card has only existing conditions
    if (
      object.conditionedTagsMissing === null &&
      object.conditionedTagsExists?.every((tag) =>
        state.tags.includes(tag.name)
      )
    ) {
      stateHandler(true);
      return object;
    }

    // Card has only missing conditions
    if (
      object.conditionedTagsExists === null &&
      object.conditionedTagsMissing?.every((tag) =>
        state.tags.includes(tag.name)
      )
    ) {
      stateHandler(true);
      return object;
    }
    // Show with conditions filter both
    if (
      object.conditionedTagsExists?.every((tag) =>
        state.tags.includes(tag.name)
      ) &&
      object.conditionedTagsMissing?.every(
        (tag) => !state.tags.includes(tag.name)
      )
    ) {
      stateHandler(true);

      return object;
    }
  };

  // Push conditioned questions
  const pushQuestionHandler = () => {
    const questionArray = [];
    questionArray.length = 0;
    const getContinionedQuestions = questionsState.map((question) => {
      if (question?.conditionedTag) {
        questionArray.push(question);
      }
      return questionArray;
    });
    questionArray?.map((question) =>
      question?.conditionedTag.map((tag) => {
        if (
          state.tags.includes(tag.name) &&
          !state.alreadyConditionedMulti.includes(question._id)
        ) {
          dispatch(setFirstTimePush());
          // Update question state
          dispatch(pushUserState(question.stateName));
          // Push question id that already added
          dispatch(alreadyConditionedMulti(question._id));
          dispatch(
            pushQuestion({
              question: question,
              inc: state.order,
              stateName: question.stateName,
            })
          );
        }
      })
    );
  };

  // Initial
  useEffect(() => {
    // Get ipopups
    ipopupHandler();
    // update conditional tags
    cardHander();
    // update flipcards
    flipCardHandler();

    // update risk layout
    riskCardHandler();
    // check to show breakdown section
    breakDownHandler({
      object: breakDown,
      stateHandler: setShowBreakDown,
      showType: 'breakdown',
    });
    // check to show risk section
    breakDownHandler({
      object: riskAssesment,
      stateHandler: setShowRiskAssessment,
      showType: 'risk',
    });
  }, []);

  useEffect(() => {
    if (riskCards.length === 0) return;
  }, [riskCards]);

  // Everytime question changed
  useEffect(() => {
    multiSelectTagHandler();
  }, [state.order]);

  // Everytime tags updated
  useEffect(() => {
    pushQuestionHandler();
    breakDownHandler({
      object: riskAssesment,
      stateHandler: setShowRiskAssessment,
      showType: 'risk',
    });
  }, [state.tags]);
  return {
    conditionCard,
    multiSelectTagHandler,
    flipCards,
    showBreakDown,
    showRiskAssessment,
    riskCards,
    popups,
  };
};

export default CardCondition;
