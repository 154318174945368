import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  margin-top: 8rem;
  text-align: center;
  h1 {
    text-align: center;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 29px;
    margin-top: 3rem;
    color: rgba(52, 49, 76, 1);
  }
`;

const OurPartners = () => {
  const ourPartners = useSelector(
    (state) => state.quiz.defaultJson.ourPartners
  );

  const { isActive, Title, logos } = ourPartners;

  if (!isActive) return '';

  return (
    <Wrapper>
      <div class='logo-slider'>
        <h1>{Title}</h1>
        <div class='logo-slide-track'>
          {logos?.map((logo) => (
            <div class='slide'>
              <img src={logo.logo.asset?.url} alt={logo.name} />
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default OurPartners;
