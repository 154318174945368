import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import CardCondition from '../../Hooks/useConditionedCards';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
  min-height: 300px;
  .flip {
    &-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 200px;
      padding: 1.5rem;
      height: 100%;
      border-radius: 8px;
      cursor: pointer;
    }
    &-front {
      background: #00b746;
      img {
        max-width: 100px;
        margin-bottom: 2rem;
      }
    }
    &-back {
      background: #fff;
      border: 1px solid #00000061;
    }
  }
`;

const FlipCards = ({ result, resultCost, relDiff, flips }) => {
  if (flips.length === 0) return '';

  return (
    <Wrapper>
      {flips.map((flip) => (
        <FlipCard
          relDiff={relDiff}
          resultCost={resultCost}
          result={result}
          key={flip._key}
          {...flip}
        />
      ))}
    </Wrapper>
  );
};

const FlipCard = ({
  cardBackColor,
  cardBackText,
  cardFrontColor,
  cardFrontIcon,
  cardFrontText,
  result,
  resultCost,
  relDiff,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  const replaceHandler = (text) => {
    return text
      .replace('{savings}', '$' + numeral(result).format('0.0a'))
      .replace(
        '{percentage}',
        `<span id="costs-percetage">${relDiff(resultCost, result)}%</span>`
      );
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection='horizontal'>
      <div
        style={{ background: cardFrontColor }}
        className='flip-card flip-front'
        onClick={handleClick}
      >
        <img src={cardFrontIcon.asset.url} alt='' />
        <p>{replaceHandler(cardFrontText)}</p>
      </div>

      <div
        style={{ background: cardBackColor }}
        className='flip-card flip-back'
        onClick={handleClick}
      >
        <p>{replaceHandler(cardBackText)}</p>
      </div>
    </ReactCardFlip>
  );
};

export default FlipCards;
