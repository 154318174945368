import { useState, useEffect } from 'react';
const sanityClient = require('@sanity/client');

export const client = sanityClient({
  projectId: '7f3wklqu',
  dataset: 'production',
  apiVersion: '2021-12-08', // use current UTC date - see "specifying API version"!
  token:
    'sk1bewm1yqIGMCSbR1OK6ZlRlY5aZ7ZlHk0tEzo1RDgvJVpfMeTSerK2JjrdK5MjgyMOroh9zj7uvWOTYxjPeVW7LKRKNpCnPl5vOjeppHBm53KMewVuaCv01hx5uhu0m4qfFTDcRu23ragcucLnmEetTLCmmCxrxRuWj22eGUYtWiCH42p2', // or leave blank for unauthenticated usage
  useCdn: true, // `false` if you want to ensure fresh data
  ignoreBrowserTokenWarning: true,
});
const params = 0;
export const popup =
  '*[_type == "3steps"] {...,content[]{...,content[]{...,asset->},image{asset->},showMeHowRef->{...,content[]{...,showMeHowRef->}}}}';

export const useCaseQuery =
  '*[_type == "3stepsUseCase"] {...,content[]{...,content[]{...,asset->},image{asset->},showMeHowRef->{...,content[]{...,showMeHowRef->}}}}';

const useSanityFetch = ({ id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const fetchHandler = async () => {
    try {
      setLoading(true);
      const sanity = await client.fetch(popup, params);
      setData(sanity?.find((item) => item.id.current === id));
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHandler();
  }, []);

  return [data, loading, error];
};

export default useSanityFetch;
