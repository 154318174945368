import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useQuestionHandler = () => {
  const [allowNext, setAllowNext] = useState(false);
  const [automateNext, setAutomateNext] = useState(false);
  const [hideNext, setHideNext] = useState(true);
  const state = useSelector((state) => state.quiz);
  const order = state.order;
  const currentQuestion = state?.defaultJson?.steps[0]?.fields[order];
  const answers = state?.userState;

  const cleanup = () => {
    setAllowNext(false);
    setAutomateNext(false);
  };

  const questionPassHandler = () => {
    // Handle Allow Next
    if (
      currentQuestion?.automateNext &&
      answers[currentQuestion?.stateName]?.length === 0
    ) {
      setAutomateNext(true);
    }
    if (currentQuestion?.skip) {
      setAllowNext(true);
      return;
    }

    // For none skip questions

    if (
      answers[currentQuestion?.stateName]?.length === 0 ||
      answers[currentQuestion?.stateName] === 0
    ) {
      setAllowNext(false);
      return;
    }
    setAllowNext(true);
  };

  useEffect(() => {
    cleanup();
    questionPassHandler();
  }, [currentQuestion]);

  useEffect(() => {
    questionPassHandler();
  }, [answers]);

  return [allowNext, automateNext, hideNext];
};

export default useQuestionHandler;
