import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserState } from '../../Redux/quizSlice';

const BreakDown = ({ style }) => {
  const dispatch = useDispatch();
  // Calculation
  const state = useSelector((state) => state.quiz);
  const defaultJson = state.defaultJson;
  const { stats } = defaultJson;
  const tabMenus = stats.tabMenus;
  const { numberEmployees } = state.userState;

  // Factory Functions to calculate
  const calculate = ({ numberEmployees, variable, divider }) => {
    return Math.floor(numberEmployees * variable * divider);
  };
  // Send to server
  const withFormula = tabMenus.map((item, index) => {
    const calc = item.label.map((label) => {
      const checkCondition = label?.conditionList?.map((ct) => {
        if (ct.tagFound.every((tag) => state.tags.includes(tag.name))) {
          return ct.change;
        }
      });

      return {
        name: label.title,
        inCard: {
          inCard: label?.inCard,
          inCardSubtitle: label?.inCardSubtitle,
          inCardTitle: label?.inCardTitle,
          linkTitle: label?.linkTitle,
          mainCardContent: label?.mainCardContent,
          mainCardTitle: label?.mainCardTitle,
          logo: label?.inCardLogo,
        },
        formulaVariable: label.formulaVariable,
        color: label.color,
        tooltipText: label.tooltipText,
        result: calculate({
          numberEmployees,

          // Conditional change
          variable:
            checkCondition[0] !== undefined
              ? (label.formulaVariable * checkCondition) / 100
              : label.formulaVariable,
          divider: 1,
        }),
      };
    });

    // (label.formulaVariable * label.change) / 100,
    // .every((tag) =>
    //         state.tags.includes(tag.name)
    //       )
    return {
      items: [...calc],
      menu: item.name,
      withFormula: null,
      cost: item.costs,
    };
  });

  useEffect(() => {
    const finalCalculate = withFormula.map((ctx) => {
      const coggniCompare = ctx.cost[0].cognniCost.formulaVariable;
      const yourCostCompare = ctx.cost[0].yourCost.formulaVariable;

      const object = ctx.items.map((ctx) => ctx.result);
      const sum = object.reduce((a, b) => a + b, 0);
      ctx.withFormulaCompare = Math.floor(numberEmployees * coggniCompare);

      ctx.withOutFormulaCompare = ctx.items
        .map((ctx) => {
          return ctx.result;
        })
        .reduce((a, b) => a + b, 0);
      ctx.savings = ctx.withOutFormulaCompare;
      ctx.savingsCompare =
        ctx.withOutFormulaTabCompare - ctx.withFormulaCompare;
      return ctx;
    });

    dispatch(updateUserState(finalCalculate));
  }, [dispatch]);

  return <section className={style.breakDown}></section>;
};

export default BreakDown;
