import React, { useEffect } from 'react';
import style from '../../Scss/Steps.module.scss';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { Terms } from '../Terms';

const Astral = styled.p`
  color: var(--red);
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Input = ({
  nextButtonHandler,
  errorValue,
  fields,
  formStateHandler,
  errorClassHandler,
  order,
  index,
  disableNextButtonHandler,
  form,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  useEffect(() => {
    if (fields.skip && form[fields.stateName].length === 0 && order === index) {
      formStateHandler({
        field: fields.stateName,
        value: 'skip',
      });
    }
  }, [order]);

  return (
    <div className={style.input_box}>
      {/* <TextWrapper>
        <p className={style.input_title}>{fields.text}</p>
      
      </TextWrapper> */}

      <input
        onChange={(e) => {
          if (
            fields.validation === 'email' &&
            e.target.value.includes('@') &&
            form[fields.stateName].length !== 0
          ) {
          }
          if (
            fields.validation === 'text' &&
            form[fields.stateName]?.length !== 0
          ) {
          }

          if (e.target.value.length === 0) {
          }

          formStateHandler({
            field: fields.stateName,
            value: e.target.value,
          });
        }}
        type={fields.validation}
        className={`${style.input} ${
          errorValue && errorClassHandler(fields.stateName)
            ? style.submitError
            : ''
        }`}
        placeholder={fields.placeholder}
        defaultValue={fields.validation === 'email' ? cookies?.email : ''}
      />
      {fields.validation === 'email' && <Terms />}
    </div>
  );
};

export default Input;
