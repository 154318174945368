import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Card from './Card';
import Tab from './Tab';
import ShowButton from './ShowButton';
import Pin from '../Pin';
import SaveCounter from './SaveCounter';
import CardCondition from '../../Hooks/useConditionedCards';
import Button from '../Button';
import { useLocation } from 'react-router';
import RecommendationPopup from '../RecommendationPopup';
import LastSection from '../LastSection';
import NewPopup from '../newPopup';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* padding: 0rem 0 0 0; */
  background: center bottom / 100% rgb(221, 242, 247);
  justify-content: center;

  .cta_reflesh {
    align-self: center;
    justify-self: center;
    margin: 3rem 1rem;
  }

  /* gap: 3rem; */
  @media (max-width: 50em) {
    grid-template-columns: auto;
  }
`;

const Title = styled.p`
  margin: 0 auto;
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  margin: 4rem 0 4rem 0;
`;

const CardContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  background: #fff;
`;

const SvgWrapper = styled.div`
  grid-column: 1/3;
  @media (max-width: 50em) {
    grid-column: unset;
  }
`;

const TabWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-auto-flow: column;
  justify-self: flex-start;
  gap: 1.6rem;
  cursor: pointer;
  @media (max-width: 50em) {
    grid-column: unset;
    justify-self: center;
  }
`;

const Recommendation = ({ show }) => {
  const [tabindex, setTab] = useState(0);
  const state = useSelector((state) => state.quiz);
  const dynamic = useSelector((state) => state.dynamic);
  const [hideButton, setHideButton] = useState(false);
  const cards = CardCondition({ type: 'recommendations' }).conditionCard;
  const location = useLocation();
  const param = location?.search;

  // const isActive = recommendation?.active;

  // if (!isActive) return '';

  return !show ? (
    // <ShowButton showButton={recommendation?.showButton} setShow={setShow} />
    ''
  ) : (
    <Wrapper>
      {/* <SvgWrapper>
        <Pin arrow />
      </SvgWrapper> */}
      {/* <TabWrapper>
        {recommendation.tabs.map((tab, index) => (
          <Tab
            key={index}
            {...tab}
            setTab={setTab}
            tab={tabindex}
            index={index}
          />
        ))}
      </TabWrapper> */}
      <Title>{state.defaultJson.recommendation.title}</Title>
      {/* <CardContainer>
        {recommendation.tabs[tabindex].cards.map((items, index) => (
          <Card key={index} {...items} />
        ))}
        <SaveCounter />
      </CardContainer> */}
      {!state.defaultJson.showWizard ? (
        <CardContainer>
          {cards?.map((items, index) => (
            <Card key={index} {...items} />
          ))}
        </CardContainer>
      ) : (
        <iframe
          src='https://irecommend.cognni.ai/?id=301'
          width='100%'
          height='750px'
          scrolling='no'
        ></iframe>
      )}
      {state.defaultJson?.recommendation?.buttonClicked &&
        !state.defaultJson.showWizard && (
          <a className='cta_reflesh' href={'/' + param}>
            <Button
              type='btnBlue'
              size='btnMd'
              text={state.defaultJson?.recommendation?.ctaButton}
            />
          </a>
        )}
      {hideButton && state?.defaultJson?.showWizard && (
        <div style={{ marginTop: '5rem' }}>
          <LastSection offToggle />
        </div>
      )}
      <SaveCounter />
    </Wrapper>
  );
};

export default Recommendation;
