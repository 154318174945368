import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import style from '../../Scss/Steps.module.scss';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage';
import QuesionTypeHandler from '../../Handlers/QuestionTypeHandler';
import RelatedQuestions from '../RelatedQuestions';
import { Terms } from '../Terms';
import { useDispatch, useSelector } from 'react-redux';
import {
  recommendation,
  worryAbout,
  shouldDo,
  temp,
} from '../../Redux/dynamicSlice';
import RopeMob from '../Timelines/RopeMob';
import FirstLine from '../Steps/svg-line/FirstLine';
import { useTotalQuestion } from './useTotalQuestion';
import ProgressCircles from './ProgressCircles';
import CardCondition from '../../Hooks/useConditionedCards';
import { useCookies } from 'react-cookie';
import {
  alreadyConditionedMulti,
  firstQuestionHandler,
  pushTags,
  setCurrentStateName,
  setDisableNextButton,
  setNextButton,
  setOrder,
  setQuestionOrder,
} from '../../Redux/quizSlice';
import useQuestionHandler from '../../Hooks/useQuestionHandler';

const Backward = styled.i`
  cursor: pointer;
  opacity: 1;
`;

const Container = styled.div`
  display: grid !important;
  grid-template-columns: 0fr auto;
  p {
    max-width: 500px;
  }
`;

const Wrapper = styled.form`
  height: 100%;
  animation: fade 1s forwards;

  .onePage-cta {
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
  }

  .hero-thanks {
    position: absolute;
    font-size: 4rem;
  }

  .hero-answers {
    margin-top: 7rem;
    display: grid;
    gap: 1rem;
    &-title {
      font-size: 2.5rem;
    }
  }

  .enter {
    animation: fade 1s forwards;
  }

  .anim-exit {
    animation: fade-exit 1s forwards;
  }

  .remove-btn {
    margin-right: 0.5rem;
  }

  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    display: none;
    transition: opacity 500ms ease-in;
  }

  // Question settings
  .btnBlueMutaiton {
    background: ${(props) => props.answerColor};
  }

  // Submit button
  .Steps_submit__dG0UG {
    .btnBlue,
    .btnBlueDisable {
      background: ${(props) => props.submitColor};
    }
  }
  // Analyze Button
  .Steps_submit__dG0UG {
    .btnGreen,
    .btnGreenDisable {
      background: ${(props) => props.analyeButton};
    }
  }

  // Question Color
  .sc-fotOHu {
    display: ${({ hideTitle }) => (hideTitle ? 'none' : 'block')};
    p {
      color: ${(props) => props.questionColor};
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-exit {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  margin-left: 1rem;
  /* position: relative;
  z-index: -1; */
`;

const SingleFlow = ({
  errorClassHandler,
  defaultJson,
  formStateHandler,
  form,
  setForm,
  step1,
  scrollToView,
  step2,
  state,
  results,
}) => {
  const [allowNext, automateNext, hideNext] = useQuestionHandler();
  const questionOrder = state.questionOrder;
  const dispatch = useDispatch();
  const { steps, numberOfSteps, questionSettings } = defaultJson;
  const [stopEffect, setStopEffect] = useState(false);
  const questions = steps[0].fields;
  const [
    allAnswered,
    setAllAnswered,
    setCounter,
    counter,
    totalQuestions,
    decrement,
    setDecrement,
  ] = useTotalQuestion();

  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  const { index } = steps[0];
  const [errorValue, setError] = useState(false);
  const [questionsState, setQuestionsState] = useState([]);
  const [checked, setChecked] = useState(true);
  const [buttonClicked, setButtonClicked] = useState();
  const [relatedsAnwered, setRelatedsAnswered] = useState(true);
  const [allQuestions, setAllQuestions] = useState([]);
  const dynamicZone = useSelector((state) => state.dynamic);
  const followUpInformationTitle = useSelector(
    (state) => state.quiz.followUpInformationTitle
  );

  const quiz = useSelector((state) => state.quiz);

  const [skipped, setSkipped] = useState(false);
  const [skippedAll, setSkippedAll] = useState(false);
  const [allSkip, setAllSkip] = useState(false);

  // order for single flow
  const [order, setOrder] = useState(0);

  // Indicate last question of the flow-
  const [infoQuestion, setInfoQuestion] = useState(false);
  const [infoQuestionTitle, setInfoQuestionTitle] = useState('');
  // Insert all questions of this step to one state-+
  const stateHandler = () => {
    questions.map((value) => {
      if (value.relatedQuestion) return;
      const formField = value.stateName;
      form[formField] = '';
      setForm({ ...form });
    });
  };

  const separateDynamic = () => {
    const tempData = dynamicZone.temp;
    if (!tempData) return;
    // fill up the states by conditions
    tempData?.map((ctx) => {
      ctx?.map((t) => {
        if (t._type === 'shouldDo') return dispatch(shouldDo(t));
        if (t._type === 'worryAbout') return dispatch(worryAbout(t));
        if (t._type === 'recommendationCard')
          return dispatch(recommendation(t));
      });
    });
    // reset the temp
    dispatch(temp(false));
  };

  // Insert questions to another state

  // Increment for single flow
  const incrementHandler = () => {
    setOrder(order + 1);
    // Tell first question is already answered
    dispatch(firstQuestionHandler());
  };
  // Decrement for single flow
  const decrementHandler = () => {
    setTimeout(() => {
      // dispatch(setNextButton(true));
      // dispatch(setDisableNextButton(false));
    }, 1);
    if (order === 0) return;
    setOrder(order - 1);
    setDecrement(decrement - 1);
    setAllAnswered(false);
    setRelatedsAnswered(true);
    // setCounter(counter - 1);
  };

  const checkLastStep = () => {
    if (index === defaultJson.numberOfSteps) {
      return true;
    }
    return false;
  };

  const InsertQuestions = () => {
    const filter = questions.filter((item) => !item.relatedQuestion);
    setQuestionsState([...filter]);
  };

  useEffect(() => {
    stateHandler();
    InsertQuestions();
  }, []);

  useEffect(() => {
    InsertQuestions();
  }, [questions]);

  useEffect(() => {
    questionsState.forEach((field, index) => {
      if (field.skip && order === index && skipped) {
        checkEmpty();
        setSkipped(false);
      }
    });
  }, [skipped]);

  useEffect(() => {
    questionsState.forEach((field, index) => {
      if (field.skip && skippedAll) {
        checkEmpty();
        setSkippedAll(false);
      }
    });
  }, [skippedAll]);

  // Everytime question order updated
  // useEffect(() => {
  //   if (questionOrder === null) return;
  //   const filter = questions.find(
  //     (item) => item.callQuestion && item.relatedQuestions
  //   );

  //   const find = filter.relatedQuestions.find(
  //     (ctx) => ctx.questionId === questionOrder
  //   );

  //   setQuestionsState([...questionsState, find]);
  //   // dispatch(setQuestionOrder(null));
  //   // lastQuestionHandler(order);
  //   // lastQuestionHandler();
  // }, [order]);

  const checkEmpty = () => {
    setButtonClicked(!buttonClicked);
    const result = questionsState.map((value, index) => {
      const formField = value.stateName;

      if (order !== index) return;
      dispatch(setCurrentStateName(value.stateName));

      if (form[formField] === '' || !checked || !relatedsAnwered) {
        if (questionOrder !== null) return true;
        setError(true);
        return true;
      }

      // Do something for dynamic zone...
      if (checkAllSkipable()) {
        setAllSkip(true);
      } else {
        setAllSkip(false);
      }
      separateDynamic();
      // Prevent submit button to increment counter
      setDecrement(decrement + 1);

      if (counter + 1 !== totalQuestions && decrement === counter) {
        setCounter(counter + 1);
      }

      if (
        index === order &&
        index !== questionsState.length &&
        !allAnswered &&
        relatedsAnwered
      ) {
        incrementHandler();
        setError(false);
      }

      return false;
    });
    // Check whether array includes error of empty input

    return result.some((value) => value === true);
  };

  const errorHandler = (e) => {
    e.preventDefault();

    if (checkEmpty()) return;

    form.step === index &&
      formStateHandler({
        field: 'step',
        value: !checkLastStep() ? 4 : index + 1,
      });
    setError(false);
    checkLastStep() ? scrollToView(results) : scrollToView(step2);
  };

  const checkAllSkipable = () => {
    const skipArray = [];

    // Check current questions' state
    // Detect unanswered ones
    // If all unswered skipable add skip all button
    questionsState.forEach((field, index) => {
      if (form[field.stateName]?.length === 0) {
        skipArray.push(field.skip);
      }
    });
    // Prevent to show skip all button on last question
    if (
      order === questionsState.length - 1 ||
      order === questionsState.length - 2
    )
      return false;

    return !skipArray?.some((skip) => !skip);
  };

  const skipHandler = () => {
    // Find current question with skip option
    questionsState.forEach((field, index) => {
      if (field.skip && order === index) {
        const stateName = field.stateName;
        // Update userState answer
        formStateHandler({
          field: stateName,
          value: 'skipped',
        });
        setSkipped(true);
      }
    });
  };

  const skipAllHandler = () => {
    questionsState.forEach((field, index) => {
      if (form[field.stateName].length === 0 && field.skip) {
        formStateHandler({
          field: field.stateName,
          value: 'skipped',
        });
      }
    });
    setSkippedAll(true);
  };

  const initialInfoCheck = () => {
    questionsState.forEach((fields, index) => {
      if (fields.questionType === 'context' && order === index) {
        setInfoQuestion(true);
        setInfoQuestionTitle(fields.placeholder);
        return;
      }
      if (order === index) {
        setInfoQuestion(false);
      }
    });
  };
  // Push the tags from answers
  CardCondition({ type: 'multi' });
  useEffect(() => {
    dispatch(setQuestionOrder(order));
  }, [order]);

  // Scroll handler
  // useEffect(() => {
  //   if (form.step === 1) return;
  //   window.scroll(0, window.innerHeight);
  // }, [form.step]);

  useEffect(() => {
    if (stopEffect) return;
    if (cookies.email) {
      questionsState.find((field, index) => {
        if (field.stateName === 'email') {
          formStateHandler({
            field: 'email',
            value: cookies.email,
          });
          if (order === index) {
            checkEmpty();
          }

          setTimeout(() => {
            setStopEffect(true);
          }, 1);
        }
      });
    }
  }, [quiz.userState]);

  const questionNameFinder = (state) => {
    const found = questionsState.find((field) => field.stateName === state);
    return found.name;
  };

  return (
    <Wrapper
      hideTitle={quiz.defaultJson?.hideQuestion}
      answerColor={questionSettings?.answerColor}
      submitButton={questionSettings?.buttonColor}
      analyeButton={questionSettings?.analyzeButtonColor}
      questionColor={questionSettings?.questionColor}
      ref={step1}
      onSubmit={(e) => errorHandler(e)}
      className={`${style.wrapper}  ${style.stepFirst}`}
      id='step1'
    >
      {step1.current !== undefined && form.step > 1 && (
        <FirstLine DOM={step1} step={form.step} numberOfSteps={numberOfSteps} />
      )}

      {step1.current !== undefined && (
        <RopeMob
          marginTop={60}
          color='
#FFC300'
          referance={step1}
        />
      )}
      {state.defaultJson.showWizard && form.step > 1 ? (
        <div className='hero-answers'>
          <p className='hero-answers-title'>YOUR ANSWERS</p>
          {Object.entries(quiz.userState)
            .filter(
              ([key, value]) =>
                key !== 'results' &&
                key !== 'step' &&
                value &&
                value !== 'context'
            )
            .map(([key, value]) => (
              <div className='hero-answers-item'>
                <p className='hero-answers-item-value'>
                  <strong> {questionNameFinder(key)}</strong> : <p>{value}</p>
                </p>
              </div>
            ))}
          <Button
            onClick={() => window.location.reload()}
            size='btnLg'
            type='btnGreen'
            text='See another use case'
          />
        </div>
      ) : (
        <p style={{ opacity: form.step === 1 ? 0 : 1 }} className='hero-thanks'>
          {state.defaultJson.messageSubmit}
        </p>
      )}
      <div
        style={{ opacity: form.step > 1 ? 0 : 1 }}
        className={style.input_container}
      >
        {questionsState.length > 0 &&
          questionsState.map((fields, index) => (
            <div
              className={`${order === index ? 'enter anim' : ''} ${
                counter !== index ? 'anim-exit' : ''
              }`}
              style={{
                display: `${order === index ? 'block' : 'none'}`,
                position: 'relative',
              }}
            >
              <Container className={style.step}>
                {/* <strong>Step {index}</strong> */}
                {order !== 0 && (
                  <Backward
                    onClick={decrementHandler}
                    className='fas fa-arrow-left anim-exit'
                  ></Backward>
                )}
                {!infoQuestion && !quiz.defaultJson?.hideQuestion ? (
                  <p>
                    {fields.text?.replace('{companyName}', form?.companyName)}{' '}
                    {!fields.skip && '*'}
                  </p>
                ) : (
                  <p>{followUpInformationTitle}</p>
                )}
              </Container>
              {!quiz.defaultJson?.hideQuestion && (
                <div
                  style={
                    {
                      // pointerEvents: `${counter !== index ? 'none' : 'unset'}`,
                      // opacity: `${counter !== index ? '0.7' : '1'}`,
                    }
                  }
                >
                  {QuesionTypeHandler(
                    fields,
                    index,
                    errorValue,
                    formStateHandler,
                    errorClassHandler,
                    checkEmpty,
                    order,
                    form
                  )}
                </div>
              )}

              {/* {errorValue && (
                <ErrorMessage errorValue={errorValue} checked={checked} />
              )} */}
            </div>
          ))}
      </div>

      {!quiz.defaultJson?.onlyFirstPage ? (
        <div
          style={{
            opacity: form.step > 1 || automateNext ? 0 : 1,
            pointerEvents: allowNext ? 'unset' : 'none',
          }}
          className={style.submit}
          onClick={checkEmpty}
          type='submit'
        >
          {allAnswered && decrement >= counter ? (
            <Button
              submit
              size='btnLg'
              type={`${
                form.step === index && checked && allowNext
                  ? 'btnGreen'
                  : 'btnGreenDisable'
              }`}
              text={defaultJson.ctaButton}
            />
          ) : (
            <Button
              // style={{
              //   opacity: allowNext ? 1 : 0,
              // }}
              size='btnLg'
              type={`${
                form.step === index && checked && allowNext
                  ? 'btnBlue'
                  : 'btnBlueDisable'
              }`}
              text={'Next'}
            />
          )}
          <ButtonContainer>
            {/* {questionsState.map(
            (fields, index) =>
              fields.skip &&
              order === index && (
                <span onClick={skipHandler}>
                  <Button
                    type='btnGray'
                    size='btnMd'
                    text={state.defaultJson.skipButton}
                  />
                </span>
              )
          )} */}

            {allSkip && (
              <span
                onClick={(e) => {
                  errorHandler(e);
                  // skipAllHandler();
                  formStateHandler({
                    field: 'step',
                    value: 4,
                  });
                }}
              >
                <Button
                  type='btnGray'
                  size='btnMd'
                  text={state.defaultJson.skipAllButton}
                />
              </span>
            )}
          </ButtonContainer>

          {state.isFirstQuestionAnswered && (
            <ProgressCircles
              // style={{ opacity: state.showNextButton ? 1 : 0 }}
              done={counter + 1}
              total={totalQuestions}
            />
          )}
        </div>
      ) : (
        <div className='onePage-cta'>
          {quiz.defaultJson?.ctas?.map((cta, i) =>
            cta?.link ? (
              <a href={cta.link} target='_blank'>
                <div
                  style={{
                    borderRadius: '36px',
                    color: cta?.color,
                    background: cta?.bgColor,
                    padding: '1rem 4rem',
                    fontSize: '1.8rem',
                    cursor: 'pointer',
                  }}
                >
                  {cta.text}
                </div>
              </a>
            ) : (
              <div
                style={{
                  borderRadius: '36px',
                  color: cta?.color,
                  background: cta?.bgColor,
                  padding: '1rem 4rem',
                  fontSize: '1.8rem',
                  cursor: 'pointer',
                }}
              >
                {cta.text}
              </div>
            )
          )}
        </div>
      )}

      {/* <Terms /> */}
    </Wrapper>
  );
};

export default SingleFlow;
