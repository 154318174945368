import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Logo from '../SecurityPractices/Logo';
import shortline from '../../Images/shortline.svg';
import { Doughnut } from 'react-chartjs-2';
import ReactSpeedometer from 'react-d3-speedometer';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import CardCondition from '../../Hooks/useConditionedCards';
import List from '../SecurityPractices/List';
import RichContent from '../BlockContent';
ChartJS.register(ArcElement, Tooltip, Legend);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1120px;
  display: grid;
  overflow: hidden;
`;

const GeneralContainer = styled.div`
  width: 100%;
  position: relative;
  i {
    position: absolute;
    left: 0;
    top: 0;
    padding: 2rem;
    font-size: 3rem;
    opacity: 0.3;
  }
  padding: 6rem 9.1rem;
  background: #fff;

  overflow: hidden;
  margin: 10rem auto 0 auto;

  img {
    max-width: 636px;
  }

  @media (max-width: 50em) {
    padding: 3.2rem 1rem;
    gap: 4rem;
  }
`;

export const Svg = styled.img`
  justify-self: center;
  @media (max-width: 50em) {
    display: none;
  }
`;

const Blank = styled.div`
  background: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;
`;

const Container = styled.div`
  display: grid;
  /* grid-template-columns: auto auto; */
  grid-template-columns: ${(props) => (props.singleLayout ? 'auto' : '1fr')};
  justify-content: ${(props) => (props.singleLayout ? 'flex-start' : 'center')};
  justify-items: flex-start;
  align-items: flex-start;
  gap: 2rem;
  canvas {
    pointer-events: 'none';
  }
`;

const Text = styled.p`
  font-size: 3.8rem;
  font-weight: 700;
  color: #34314c;
  text-align: center;

  @media (max-width: 50em) {
    margin-bottom: unset;
    margin-top: 2rem;
    font-size: 1.8rem;
    max-width: 243px;
  }
`;

const SubTitle = styled.p`
  margin-bottom: 3rem;
  text-align: center;
  font-size: 2rem;
  color: #34314c;

  @media (max-width: 50em) {
    margin-bottom: unset;
    font-size: 1.5rem;
    max-width: 243px;
  }
`;

const LabelWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
  grid-row: 2/3;
`;
const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
  cursor: pointer;
`;
const LabelColor = styled.div`
  width: 24px;
  height: 24px;
  background: ${(props) => props.color};
  border-radius: 50%;
`;
const LabelName = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;
const LabelContent = styled.div`
  grid-column: 2/3;
`;
const LabelLink = styled.div`
  color: blue;
  cursor: pointer;
  grid-column: 2/3;
`;

// Inline Card

const InlineContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  justify-items: center;
  margin-top: 0rem;
  width: 100%;
  /* margin-top: 6rem; */
  img {
    max-width: 200px;
  }
  @media (max-width: 50em) {
    /* padding: 4rem 3rem; */
    margin-top: 0rem;
    grid-template-columns: auto;
    gap: 4rem;
  }
`;

const ListTitle = styled.p`
  font-size: 2rem;
  font-weight: 600;
  color: #34314c;
  @media (max-width: 50em) {
    font-size: 1.6rem;
  }
`;
const ListContent = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: #565656;
  @media (max-width: 50em) {
    font-size: 1.2rem;
  }
`;

const ChartWrapper = styled.div`
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-auto-flow: column;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  .risk-content {
    text-align: left;
  }
`;

const ChartContainer = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  text-align: center;
  canvas {
    pointer-events: none;
  }
  .chart-container {
    position: absolute;
  }
  .chart-score {
    font-size: 3.6rem;
    font-weight: 600;
    color: var(--main);
  }
  .chart-label {
    color: var(--main);
  }
`;

const InCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

const options = {
  plugins: {
    tooltip: {
      enabled: false,
    },
    hover: { mode: null },
    events: [],
  },
};

const Index = () => {
  const state = useSelector((state) => state.quiz.defaultJson.riskAssesment);
  const tags = useSelector((state) => state.quiz.tags);
  const [scores, setScores] = useState([]);
  const getRiskLayout = CardCondition({ type: 'risk' }).riskCards;
  const getRiskCards = CardCondition({ type: 'risk' }).conditionCard;

  const getRiskScore = (type) => {
    if (getRiskLayout === 0) return;
    const calculated = getRiskLayout[0]?.labels?.map((label, index) => {
      let baseScore = label.labels.defaultValue * label.labels?.balance[type];
      const checkCondition = label?.labels.conditionList?.map((ct) => {
        if (ct.tagFound.every((tag) => tags.includes(tag.name))) {
          return ct.change;
        }
      });

      return checkCondition[0] !== undefined
        ? (baseScore * checkCondition) / 100
        : baseScore;
    });
    setScores((prev) => [...prev, calculated]);
    // setScores(calculated);
  };

  useEffect(() => {
    getRiskScore(0);
    getRiskScore(1);
    getRiskScore(2);
  }, [getRiskLayout]);

  // .reduce((a, b) => a + b, 0) / 100
  // create array of number
  function createArray(length) {
    return Array.from({ length }, (_, k) => k + 1);
  }

  const chartType = createArray(getRiskLayout[0]?.type);
  const [inlineCard, setInlineCard] = useState(false);
  const [inCardObject, setInCardObject] = useState();

  const [singleLayout, setSingleLayout] = useState(false);
  const riskShowHandler = CardCondition({
    type: 'risk',
  }).showRiskAssessment;

  useEffect(() => {
    setSingleLayout(chartType?.length === 1 ? true : false);
  }, [chartType]);

  // Get conditional risk cards
  if (
    !state.active ||
    !getRiskLayout[0]?.active ||
    !riskShowHandler ||
    getRiskLayout?.length === 0
  )
    return '';

  return (
    <Wrapper>
      <GeneralContainer>
        <Blank className={inlineCard ? 'anim' : 'anim1'} />
        {inlineCard ? (
          <div>
            <i
              onClick={() => setInlineCard(false)}
              class='sc-fHeRUh bZQmLv fas fa-arrow-left anim-exit'
            ></i>
            <Text>{inCardObject.inCardTitle}</Text>
            <SubTitle onClick={() => setInlineCard(false)}>
              {inCardObject.inCardSubtitle}
            </SubTitle>
            <InlineContainer>
              <InCardContainer>
                <ListTitle>{inCardObject.mainCardTitle}</ListTitle>
                <RichContent content={inCardObject.mainCardContent} />
                {/* <ListContent>{inCardObject.mainCardContent}</ListContent> */}
              </InCardContainer>
              <Logo logoAlign={'right'} src={inCardObject.inCardLogo} />
            </InlineContainer>
          </div>
        ) : (
          <div>
            <Text>{getRiskLayout[0].title}</Text>
            <SubTitle>{getRiskLayout[0].subtitle}</SubTitle>
            <Container singleLayout={singleLayout}>
              <ChartWrapper>
                {chartType.map((type, index) => (
                  <ChartContainer>
                    {/* <div className='chart-container'>
                  <p className='chart-score'>
                    {scores.reduce((a, b) => a + b, 0).toFixed(1)}
                  </p>
                  <p className='chart-label'>Risk Score</p>
                </div> */}
                    <p>{getRiskLayout[0]['chartTitle' + type]}</p>
                    <ReactSpeedometer
                      value={scores[type + 2]
                        ?.reduce((a, b) => a + b, 0)
                        .toFixed(1)}
                      maxValue={100}
                      minValue={0}
                      height={200}
                      // currentValueText='Happiness Level'
                      customSegmentLabels={[
                        {
                          position: 'INSIDE',
                          color: '#555',
                        },
                        {
                          position: 'INSIDE',
                          color: '#555',
                        },
                        {
                          position: 'INSIDE',
                          color: '#555',
                          fontSize: '19px',
                        },
                        {
                          position: 'INSIDE',
                          color: '#555',
                        },
                        {
                          position: 'INSIDE',
                          color: '#555',
                        },
                      ]}
                    />
                    {/* <Doughnut data={data} options={options} /> */}
                    {/* /* <img src='/riskAsessmentChart.svg' alt='' /> */}
                    {!singleLayout && (
                      <p className='risk-content'>
                        {getRiskLayout[0]['chartContent' + type]}
                      </p>
                    )}
                  </ChartContainer>
                ))}
                {singleLayout && getRiskCards.length === 0 && (
                  <p className='chart-desc'>
                    {getRiskLayout[0]?.chartDefaultText}
                  </p>
                )}

                {
                  <div className='risk-cards'>
                    {singleLayout &&
                      getRiskCards.length !== 0 &&
                      getRiskCards
                        .slice(0, getRiskLayout[0]?.cardLimit)
                        .sort((a, b) => a.order - b.order)
                        .map((x) => (
                          <div>
                            <p>{x.title}</p>
                            <RichContent content={x.details} />
                            {x.linkTitle && (
                              <p
                                style={{ color: 'blue', cursor: 'pointer' }}
                                onClick={() => {
                                  if (x.inlineCard) {
                                    setInCardObject({
                                      inCardTitle: x.inlineCard?.inCardTitle,
                                      inCardSubtitle:
                                        x.inlineCard?.inCardSubtitle,
                                      mainCardTitle:
                                        x.inlineCard?.mainCardTitle,
                                      mainCardContent:
                                        x.inlineCard?.mainCardContent,
                                      inCardLogo:
                                        x.inlineCard?.inCardLogo?.asset.url,
                                    });
                                    setInlineCard(true);
                                  }
                                }}
                              >
                                {x.linkTitle}
                              </p>
                            )}
                          </div>
                        ))}
                  </div>
                }
              </ChartWrapper>

              <LabelWrapper>
                {getRiskLayout[0]?.labels?.map((x, i) => (
                  <LabelContainer key={x._id}>
                    {/* <LabelColor color={x.color}></LabelColor> */}
                    <LabelName
                      onClick={() => {
                        if (x.inCard) {
                          setInCardObject({
                            inCardTitle: x.labels.inCardTitle,
                            inCardSubtitle: x.labels.inCardSubtitle,
                            mainCardTitle: x.labels.mainCardTitle,
                            mainCardContent: x.labels.mainCardContent,
                            inCardLogo: x.labels.inCardLogo?.asset.url,
                          });
                          setInlineCard(true);
                        }
                      }}
                    >
                      {singleLayout && (
                        <p>{scores && scores[3][i] !== 0 && scores[3][i]}</p>
                      )}
                      {singleLayout && scores[3][i] !== 0 && (
                        <p style={{ color: x.inCard && 'blue' }}>
                          {x.labels.title}
                        </p>
                      )}
                    </LabelName>
                    {/* <LabelContent>{x.content}</LabelContent> */}
                    {/* {x.inCard && (
                      <LabelLink
                        
                      >
                        {x.linkTitle}
                      </LabelLink>
                    )} */}
                  </LabelContainer>
                ))}
              </LabelWrapper>
            </Container>
          </div>
        )}
      </GeneralContainer>
      <Svg src={shortline} />
      <Svg src={shortline} />
    </Wrapper>
  );
};

export default Index;
