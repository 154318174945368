import React, { useState, useEffect, useLayoutEffect } from 'react';

import FirstStep from '../Components/Steps/FirstStep';
import SecondStep from '../Components/Steps/SecondStep';
import ThirdStep from '../Components/Steps/ThirdStep';
import Hero from '../Components/Hero';
import style from '../Scss/Homepage.module.scss';
import Calculation from '../Components/Calculation/Stats';
import Background from '../Components/Background';
import { useSelector, useDispatch } from 'react-redux';
import { setUserState } from '../Redux/quizSlice';
import RiskAssessment from '../Components/RiskAssessment';
import '../Components/Steps/svgLine.scss';
// Dont Delete
import TimelineWeb from '../Components/Timelines/TimelineWeb';
// Dont Delete
import CalculationStats from '../Components/Steps/CalculationStats';
import scrollIntoView from 'scroll-into-view';
import Recommendation from '../Components/Recommendation/Recommendation';
import Pin from '../Components/Pin';
import AnimatedButton from '../Components/AnimatedButton';
import SecurityPractices from '../Components/SecurityPractices';
import SingleFlow from '../Components/SingleFlow/SingleFlow';
import LastSection from '../Components/LastSection';
import RecommendButton from '../Components/RecommendButton';
import shortline from '../Images/shortline.svg';
import Chart from '../Components/Chart';
import Control from '../Components/Forms/Control';
import Popup from '../Components/Popup';
import RecommendationPopup from '../Components/RecommendationPopup';
import OurSupporters from '../Components/OurSupporters';
import OurPartners from '../Components/OurPartners';

const Homepage = ({ app, hero, results, step1, step2, step3 }) => {
  // Main state

  const state = useSelector((state) => state.quiz);
  const dispatch = useDispatch();
  const defaultJson = state.defaultJson;
  const [show, setShow] = useState(false);
  const { singleFLow } = defaultJson;
  const recPopup = state.recommendationPopupActive;

  // State that saves user answers
  const [form, setForm] = useState({
    step: 1,
    // testRelated: true,
  });
  const [isEnd, setIsEnd] = useState(true);

  // Functions
  const formStateHandler = ({ field, value }) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const scrollToView = (view) => {
    let speedInSeconds = 1;
    setTimeout(() => {
      scrollIntoView(view.current, {
        time: 500,
      });
    }, speedInSeconds);
  };

  const errorClassHandler = (field) => {
    if (form[field] === '') return true;
    return false;
  };

  useEffect(() => {
    if (defaultJson?.recommendation?.buttonClicked) {
      setShow(true);
    }
    // document.title = defaultJson.hero.page_title;
  }, [defaultJson?.recommendation?.buttonClicked]);

  // Update redux user state
  useEffect(() => {
    dispatch(setUserState(form));
  }, [form]);

  return (
    <main ref={app} className={style.wrapper}>
      {state.popup && <Popup dispatch={dispatch} />}
      <article>
        <Hero
          step1={step1}
          scrollToView={scrollToView}
          hero={hero}
          defaultJson={defaultJson}
          form={form}
          formStateHandler={formStateHandler}
          errorClassHandler={errorClassHandler}
          state={state}
          step2={step2}
          setForm={setForm}
          results={results}
        />
        {recPopup && <RecommendationPopup />}
        {isEnd && (
          <>
            {!singleFLow && form.step >= 1 ? (
              ''
            ) : (
              <section className={style.steps}>
                {form.step >= 1 && (
                  <FirstStep
                    errorClassHandler={errorClassHandler}
                    step2={step2}
                    scrollToView={scrollToView}
                    step1={step1}
                    defaultJson={defaultJson}
                    state={state}
                    formStateHandler={formStateHandler}
                    form={form}
                    setForm={setForm}
                    results={results}
                  />
                )}
                {form.step >= 2 && defaultJson.numberOfSteps >= 2 && (
                  <Background>
                    <SecondStep
                      errorClassHandler={errorClassHandler}
                      step3={step3}
                      scrollToView={scrollToView}
                      step2={step2}
                      defaultJson={defaultJson}
                      formStateHandler={formStateHandler}
                      form={form}
                      setForm={setForm}
                      results={results}
                    />
                  </Background>
                )}
                {form.step >= 3 && defaultJson.numberOfSteps >= 3 && (
                  <ThirdStep
                    errorClassHandler={errorClassHandler}
                    hero={hero}
                    scrollToView={scrollToView}
                    step3={step3}
                    defaultJson={defaultJson}
                    formStateHandler={formStateHandler}
                    form={form}
                    setForm={setForm}
                    results={results}
                  />
                )}
              </section>
            )}

            {form.step >= 4 && (
              <>
                <SecurityPractices />
                <Background>
                  <RiskAssessment />
                </Background>

                <Calculation defaultJson={defaultJson} />
                <CalculationStats
                  state={state}
                  results={results}
                  setShow={setShow}
                />
                <Background>
                  <div
                    onClick={() => setShow(true)}
                    className='stats-recommend'
                  >
                    {!show && defaultJson?.recommendation?.active && <RecommendButton text='See Recommendation' />}
                  </div>
                </Background>

                <Recommendation show={show} />
                {show && (
                  <>
                    <Background>
                      <OurPartners />
                    </Background>
                    <Background>
                      <OurSupporters />
                    </Background>

                    {!defaultJson?.recommendation?.buttonClicked ? (
                      <Pin />
                    ) : (
                      <Background>
                        <div style={{ paddingTop: '8rem' }}></div>
                      </Background>
                    )}

                    {defaultJson?.connection && defaultJson?.onlyFirstPage && (
                      <Background>
                        <LastSection />
                      </Background>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </article>
    </main>
  );
};

export default Homepage;
