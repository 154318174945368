import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1120px;
  margin-top: 8rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ul {
    text-decoration: none;
    list-style: none;
    margin-right: 2rem;
    width: 100%;
    padding-left: 0rem;
  }

  li {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    color: #565656;
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }

  .support {
    &-title {
      text-align: center;
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 29px;
      margin-bottom: 5rem;
      color: rgba(52, 49, 76, 1);
    }
    &-column {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const OurSupporters = () => {
  const [show, setShow] = useState(true);

  const state = useSelector((state) => state.quiz.defaultJson?.ourSupporters);
  const { ourSupporters } = state;
  if (!state?.isActive) return '';
  return (
    <Wrapper>
      <p className='support-title'>{ourSupporters.title}</p>
      <div className='support-column support-desc'>
        <ul>
          {ourSupporters?.listItems.map((item, index) => (
            <li>
              <img
                src='https://cognni.ai/wp-content/uploads/2022/02/Check.png'
                alt=''
              />
              <p>{item?.children.map((ctx) => ctx.text)}</p>
            </li>
          ))}
        </ul>
        <img src={ourSupporters.logo?.asset.url} alt='' />
      </div>
    </Wrapper>
  );
};

export default OurSupporters;
