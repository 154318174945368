import React, { useState, useEffect } from 'react';
import style from '../../Scss/Steps.module.scss';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  pushTags,
  setNextButton,
  setQuestionOrder,
  tempTagHandler,
} from '../../Redux/quizSlice';
import { temp } from '../../Redux/dynamicSlice';

const Options = ({
  errorValue,
  fields,
  formStateHandler,
  errorClassHandler,
  checkEmpty,
  nextButtonHandler,
  disableNextButtonHandler,
  order,
  index,
  form,
}) => {
  const dispatch = useDispatch();
  const [shortAnswer, setShortAnswer] = useState({
    type: 'shortAnswer',
    isAnswered: false,
    index: null,
  });

  const tempTag = useSelector((state) => state.quiz?.temporaryTag);
  const question = useSelector(
    (state) => state.quiz?.defaultJson?.steps[0].fields
  );

  const currentStateName = useSelector((state) => state.quiz?.currentStateName);

  useEffect(() => {
    if (!shortAnswer.isAnswered) return;
    // disableNextButtonHandler(fields.skip ? true : false);
    if (fields.automateNext) {
      checkEmpty();
    }
  }, [shortAnswer]);

  useEffect(() => {
    if (fields.skip && form[fields.stateName].length === 0 && order === index) {
      formStateHandler({
        field: fields.stateName,
        value: 'skip',
      });
    }

    if (!fields.automateNext && order !== index) {
      dispatch(pushTags(tempTag));
      dispatch(tempTagHandler([]));
    }
  }, [order]);

  return (
    <div className={style.input_box}>
      {/* <p className={style.input_title}>{fields.text}</p> */}
      <div className={style.boolean_layout}>
        {fields.options.map((btn, i) => (
          <span
            key={i}
            onClick={() => {
              setShortAnswer({
                ...shortAnswer,
                isAnswered: true,
                index: i,
              });

              // dispatch(setQuestionOrder(btn?.information?._id));
              // numberEmployees: null,
              formStateHandler({
                field: fields.stateName,
                value: btn.text,
              });

              if (btn.conditionList && !fields.automateNext) {
                dispatch(tempTagHandler(btn?.conditionList.map((x) => x.name)));
              }

              if (fields.automateNext) {
                dispatch(pushTags(btn?.conditionList.map((x) => x.name)));
              }
              // Deploy temp data of dynamic sections
              // dispatch(
              //   temp([
              //     btn.callRecommendation,
              //     btn.callWorryAbout,
              //     btn.callShouldDo,
              //   ])
              // );
            }}
          >
            <Button
              icon={btn.icon}
              error={`${
                errorValue && errorClassHandler(fields.stateName) ? true : false
              }`}
              text={btn.text}
              size='btnSm'
              type={
                shortAnswer.isAnswered && shortAnswer.index === i
                  ? 'btnBlueMutaiton'
                  : 'btnWhite'
              }
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default Options;
