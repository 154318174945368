import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import style from '../Scss/Hero.module.scss';
import Button from './Button';
import HeroQuestion from './HeroQuestion';
import SingleFlow from './SingleFlow/SingleFlow';
import { useSelector } from 'react-redux';
import VideoPlayer from './VideoPlayer';
import LastSection from './LastSection';

const Error = styled.p``;

const Hero = ({
  formStateHandler,
  form,
  defaultJson,
  hero,
  scrollToView,
  step1,
  errorClassHandler,
  state,
  step2,
  setForm,
  results,
}) => {
  const questionOrder = useSelector((state) => state.quiz.order);
  const [heroState, setHeroState] = useState({});
  const [checked, setChecked] = useState(true);
  const heroQuestion = defaultJson.steps[0].fields;
  const image = heroQuestion[questionOrder]?.infoImage?.asset.url;
  const video =
    heroQuestion[questionOrder]?.videoBlogPost?.video?.asset.playbackId;
  const igniteForm = () => {
    if (checked === null || !checked) {
      setChecked(false);
      return;
    }
    form.step === '' && formStateHandler({ field: 'step', value: 1 });
    // scrollToView(step1);
  };

  useEffect(() => {
    setHeroState({
      welcomePage: defaultJson.hero.title,
      subTitle: defaultJson.hero.sub_title,
      button: defaultJson.hero.button_text,
    });
  }, [form.step]);

  const { welcomePage, subTitle, button } = heroState;
  const initialQuestion = defaultJson.hero.initialQuestion;
  return (
    <section
      ref={hero}
      id='hero'
      style={{
        backgroundImage: `url(${
          defaultJson.hero.page_background.asset.url ||
          'https://cognni.ai/wp-content/uploads/2022/02/Clouds-move.gif'
        })`,
      }}
      className={style.wrapper}
    >
      <div className={style.hero_container}>
        <div className={style.hero}>
          <h1
            className={style.title}
            dangerouslySetInnerHTML={{ __html: welcomePage }}
          />
          <p
            dangerouslySetInnerHTML={{ __html: subTitle }}
            className={style.description}
          />
          {!initialQuestion ? (
            <SingleFlow
              errorClassHandler={errorClassHandler}
              step2={step2}
              scrollToView={scrollToView}
              step1={step1}
              defaultJson={defaultJson}
              state={state}
              formStateHandler={formStateHandler}
              form={form}
              setForm={setForm}
              results={results}
            />
          ) : (
            button && (
              <span onClick={() => igniteForm()}>
                <Button shadow text={button} type='btnBlue' size='btnSm' />
              </span>
            )
          )}
        </div>
        {video ? (
          <VideoPlayer data={video} />
        ) : (
          <img
            src={image ? image : defaultJson.hero.logo.asset.url}
            alt='logo-company'
            className={style.logo}
          />
        )}
      </div>

      {defaultJson?.connection && defaultJson?.onlyFirstPage && (
        <div className='hero_cta'>
          <LastSection offToggle />
        </div>
      )}
    </section>
  );
};

export default Hero;
