import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import { defaultJson } from '../Json/default';
// import { kizanJson } from '../Json/kizan';
import { securityPractices } from '../Json/SecurityPractices';
import { singleFlow } from '../Json/singleFlow';
const sanityClient = require('@sanity/client');
// Sanity Settings
const client = sanityClient({
  projectId: '0bflv8ro',
  dataset: 'production',
  apiVersion: '2021-12-08', // use current UTC date - see "specifying API version"!
  token:
    'skhwJrSNZ4vGqOKX544hAr1EjTrZqsbjqmuUMpRPp5DooWuPsMZNAxJmdJncW7yDQeKJkUWplcuJbxQWgbgdd1ik6miVFIV7sUCq88QGrSp6BL6RFU3rSOSkqFXbbRkul4tgPgKPf9D4O2NqCWuTwTmwcRCbWqjL4MzraJoJBnqew1YaWrPJ', // or leave blank for unauthenticated usage
  useCdn: true, // `false` if you want to ensure fresh data
});
const query =
  '*[_type == "partner"] { ...,defaultReccomendation->,ctas[]->,videoBlogPost{..., video{asset->{...}}},ourSupporters{..., ourSupporters->{..., logo{...,asset->}}},ourPartners{...,logos[]->{...,logo{..., asset->}}},whyUsSection{...,flipCards[]->{...,conditionedTagsExists[]->{...},conditionedTagsMissing[]->{...},cardFrontIcon{...,asset->}}},tabMenus{...},connection[]->{..., inputs[]->{...}}, hero{...,page_background{..., asset->},favicon{...,asset->},logo{...,asset->},fields->{..., options[]{...,information->}}},stats {...,tabMenus[]->{...,conditionedTagsExists[]->{...},conditionedTagsMissing[]->{...},label[]->{...,inCardLogo{..., asset->},conditionList[]{...,tagFound[]->}}}}, steps[] {...,relatedQuestions[]->{...},fields[]-> {...,videoBlogPost{..., video{asset->{...}}},infoImage{...,asset->},numericCondition[]{...,conditionedTag[]->},options[]{...,information->{...},conditionList[]->{...},callRecommendation[]->,callShouldDo[]->,callWorryAbout[]->, CallOnAnswer->{...,options[]{..., CallOnAnswer->}}}}},riskAssesment{...,conditionedTagsExists[]->{...},conditionedTagsMissing[]->{...},labels[]->{...,inCardLogo{..., asset->},tagFound[]->{...}}} }';
const params = 0;

const card =
  '*[_type == "card"] {...,detailPopup->{..., content[]{...,showMeHowRef->{...,content[]{...,showMeHowRef->}}, image{..., asset->}}},inlineCard{...,inCardLogo{asset->}},conditionedTagsExists[]->{...},conditionedTagsMissing[]->{...},image{asset->{...}}}';
// const data = await client.fetch(query, params)
const submission =
  '*[_type == "submission"] {..., card[]{..., image{...,asset->}} ,image{..., asset->}}';

const questions =
  '*[_type == "question"] {...,videoBlogPost{..., video{asset->{...}}},infoImage{...,asset->},conditionedTag[]->,options[]{...,conditionList[]->}}';

const flipCards =
  '*[_type == "flipCard"] {...,conditionedTagsExists[]->{...},conditionedTagsMissing[]->{...},cardFrontIcon{...,asset->}}';

const riskCards =
  '*[_type == "riskAssesment"] { ...,labels[]{..., labels->{...,conditionList[]{...,tagFound[]->}}},conditionedTagsExists[]->{...},conditionedTagsMissing[]->{...} }';

const ipopups =
  '*[_type == "recommedationPopup"] {...,content[]{...,showMeHowRef->{...,content[]{...,showMeHowRef->}}},conditionedTagsExists[]->{...},conditionedTagsMissing[]->{...}}';

const initialState = {
  defaultJson: null,
  userState: false,
  globalStepHeight: 0,
  questionOrder: null,
  relatedQuestionsState: [],
  initialInformation: {},
  followUpInformationTitle: '',
  popup: false,
  cards: [],
  tags: [],
  submissions: [],
  recommendationPopup: [],
  recommendationPopupActive: false,
  showNextButton: false,
  disableNextButton: false,
  questions: [],
  alreadyConditionedMulti: [],
  order: 0,
  currentStateName: false,
  flipCards: [],
  firstTimePush: true,
  isFirstQuestionAnswered: false,
  temporaryTag: [],
  numericConditionPushed: false,
  ipopups: [],
};

export const fetchIpopups = createAsyncThunk(
  '/api/sanity/ipopups',
  async () => {
    const sanity = await client.fetch(ipopups, params);
    return sanity;
  }
);

export const fetchPartnerTheme = createAsyncThunk(
  '/api/sanity',
  async ({ uuid }) => {
    const sanity = await client.fetch(query, params);
    return sanity.find((ctx) => ctx.hero.pageId === uuid);
  }
);

export const fetchFlipCards = createAsyncThunk(
  '/api/sanity/flipcards',
  async () => {
    const sanity = await client.fetch(flipCards, params);
    return sanity;
  }
);

export const fetchSubmissions = createAsyncThunk(
  '/api/sanity/submissions',
  async ({ uuid }) => {
    const sanity = await client.fetch(submission, params);
    return sanity.find((ctx) => ctx.uuid === uuid);
  }
);

export const fetchCards = createAsyncThunk('/api/sanity/cards', async () => {
  const sanity = await client.fetch(card, params);
  return sanity;
});

export const fetchRiskCards = createAsyncThunk(
  '/api/sanity/riskCards',
  async () => {
    const sanity = await client.fetch(riskCards, params);
    return sanity;
  }
);

export const fetchQuestions = createAsyncThunk(
  '/api/sanity/questions',
  async () => {
    const sanity = await client.fetch(questions, params);
    return sanity;
  }
);

export const quizSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    pushQuestion: (state, action) => {
      let questionIndex;

      current(state).defaultJson.steps[0].fields.forEach((ctx, index) => {
        console.log(ctx.stateName, current(state).currentStateName);
        if (ctx.stateName === current(state).currentStateName) {
          questionIndex = index + 1;
        }
      });

      state.defaultJson.steps[0].fields.splice(
        questionIndex,
        0,
        action.payload.question
      );
    },
    setDisableNextButton: (state, action) => {
      state.disableNextButton = action.payload;
    },
    numericConditionHandler: (state, action) => {
      state.numericConditionPushed = true;
    },
    tempTagHandler: (state, action) => {
      state.temporaryTag = action.payload;
    },
    firstQuestionHandler: (state) => {
      state.isFirstQuestionAnswered = true;
    },
    setNextButton: (state, action) => {
      state.showNextButton = action.payload;
    },
    setPopup: (state, action) => {
      state.popup = action.payload;
    },
    setCurrentStateName: (state, action) => {
      state.currentStateName = action.payload;
    },
    setUserState: (state, action) => {
      if (action.payload === 'connect') {
        state.userState.step = 5;
        return;
      }
      state.userState = action.payload;
    },
    updateUserState: (state, action) => {
      state.userState.results = action.payload;
    },
    pushUserState: (state, action) => {
      state.userState[action.payload] = true;
      // state.userState = {state.userState, action.payload};
    },
    getScrollSize: (state, action) => {
      state.scrollSize = action.payload;
    },
    setFirstTimePush: (state, action) => {
      state.firstTimePush = false;
    },
    updateStepHeight: (state, action) => {
      state.globalStepHeight = action.payload;
    },
    // Set question order for related questions
    setQuestionOrder: (state, action) => {
      state.order = action.payload;
    },
    setRelatedQuestionState: (state, action) => {
      const questionState = state.relatedQuestionsState;
      state.relatedQuestionsState = [...questionState, action.payload];
    },
    alreadyConditionedMulti: (state, action) => {
      state.alreadyConditionedMulti = [
        ...state.alreadyConditionedMulti,
        action.payload,
      ];
    },
    updateJson: (state, action) => {
      const payload = action.payload;
      switch (payload) {
        case 'securityPractices':
          state.defaultJson = securityPractices;
          break;
        // case 'kizan':
        //   state.defaultJson = kizanJson;
        //   break;
        case 'singleFlow':
          state.defaultJson = singleFlow;
          break;
        case 'default':
          state.defaultJson = defaultJson;
          break;

        default:
          console.log('No JSON provided');
      }
    },
    updateInformation: (state, action) => {
      state.initialInformation = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    pushTags: (state, action) => {
      console.log(action.payload);
      state.tags = [...state.tags, ...action.payload];
    },
    pingFollowUpQuestion: (state, action) => {
      state.followUpInformationTitle = action.payload;
    },
    setRecPopup: (state, action) => {
      state.recommendationPopup = action.payload;
    },
    setRecPopupActive: (state, action) => {
      state.recommendationPopupActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRiskCards.fulfilled, (state, action) => {
      state.riskCards = action.payload;
    });
    builder.addCase(fetchPartnerTheme.fulfilled, (state, action) => {
      console.log(action);
      state.defaultJson = action.payload;
    });
    builder.addCase(fetchCards.fulfilled, (state, action) => {
      state.cards = action.payload;
    });
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      state.questions = action.payload;
    });
    builder.addCase(fetchSubmissions.rejected, (state, action) => {});
    builder.addCase(fetchSubmissions.fulfilled, (state, action) => {
      state.submissions = action.payload;
    });
    builder.addCase(fetchFlipCards.fulfilled, (state, action) => {
      state.flipCards = action.payload;
    });
    builder.addCase(fetchIpopups.fulfilled, (state, action) => {
      state.ipopups = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserState,
  updateUserState,
  updateStepHeight,
  updateJson,
  setQuestionOrder,
  setRelatedQuestionState,
  updateInformation,
  pingFollowUpQuestion,
  setPopup,
  pushTags,
  setRecPopup,
  setRecPopupActive,
  setNextButton,
  setDisableNextButton,
  alreadyConditionedMulti,
  setOrder,
  pushQuestion,
  pushUserState,
  setFirstTimePush,
  setCurrentStateName,
  firstQuestionHandler,
  tempTagHandler,
  numericConditionHandler,
} = quizSlice.actions;

export default quizSlice.reducer;
