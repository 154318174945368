import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setRecPopup, setRecPopupActive } from '../Redux/quizSlice';
import styled from 'styled-components';
import CardCondition from '../Hooks/useConditionedCards';

const BlockContent = require('@sanity/block-content-to-react');
const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

const Container = styled.div`
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100%;
  z-index: 999;
  top: 0;
  background: ${(props) => (props.component ? 'unset' : ' #00000020')};
  position: ${(props) => (props.component ? 'unset' : 'fixed')};
  .show-me {
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
  }
`;

const Wrapper = styled.div`
  justify-self: center;
  width: 100%;
  min-height: 700px;
  background: #fff;
  display: grid;
  grid-template-rows: 0fr 0fr;
  max-width: 1000px;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 8px;

  i {
    justify-self: flex-end;
    font-size: 2rem;
    cursor: pointer;
  }
  .popup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .popup-content {
    display: grid;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    text-align: left;
    flex-grow: 1;
    margin-top: 3rem;

    color: gray;
    &-container {
      display: grid;
      gap: 1rem;
      justify-items: flex-start;
    }
    &-title {
      font-weight: 600;
    }
    &-img {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }

    img {
      display: inline-block;
    }
  }
  .popup-buttons {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    justify-content: space-between;

    gap: 2rem;
    margin-top: 1rem;
    button {
      background: #fff;
      border: solid 1px black;
      height: 40px;
      padding: 0 2rem;
      border-radius: 4px;
      cursor: pointer;
      box-shadow: 2px 3px 9px -2px rgba(0, 0, 0, 0.17);
      -webkit-box-shadow: 2px 3px 9px -2px rgba(0, 0, 0, 0.17);
      -moz-box-shadow: 2px 3px 9px -2px rgba(0, 0, 0, 0.17);
    }
    .popup-next {
      background: rgb(33, 149, 243);
      color: #fff;
      border: solid 0px black;
    }
  }
  .popup-control {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    justify-items: center;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 1.5rem;
    gap: 2rem;
    background: #80808010;
    border-radius: 8px;
    padding: 2rem;

    #popup-circle {
      display: grid;
      place-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transition: 0.3s;
      cursor: pointer;
      font-weight: 700;
    }
    .popup-line {
      width: 100%;
      height: 2px;
      background: gray;
      &-dot {
        background: transparent;
        border: none;
        border-top: 2px dotted gray;
      }
    }
    .circle-container {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      gap: 2rem;
      width: 100%;
      height: 100%;
    }
  }
  .control-active {
    background: rgb(33, 149, 243);
    color: #fff;
  }
  .control-disable {
    box-shadow: 2px 3px 9px 0px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 2px 3px 9px 0px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 2px 3px 9px 0px rgba(0, 0, 0, 0.17);
  }
`;

const RecommendationPopup = ({
  component = false,
  hideButton,
  setHideButton,
}) => {
  let popupArr = [];
  const [focus, setFocus] = useState(0);
  const [activeColor, setActiveColor] = useState('');
  const state = useSelector((state) => state.quiz);
  const [popupPrevious, setPopupPrevious] = useState(null);
  const [popupPassed, setPopupPassed] = useState(false);
  const popup = state.recommendationPopup;
  const ipopups = CardCondition({ type: 'ipopup' }).popups?.find(
    (popup) => popup?.conditionedTagsExists && popup?.conditionedTagsMissing
  );

  const dispatch = useDispatch();
  useEffect(() => {
    popup?.content?.forEach((x, i) => {
      if (focus === i) {
        setActiveColor(x.activeColorBg);
      }
    });

    if (component) {
      if (focus === popup?.content?.length - 1) {
        setHideButton(true);
        return;
      }
      setHideButton(false);
    }
  }, [focus]);

  useEffect(() => {
    setFocus(0);
    setActiveColor(popup?.content?.find((x, i) => i === focus)?.activeColorBg);
    if (!popupPrevious) {
      setPopupPrevious(popup);
    }
  }, [popup]);

  const controlHandler = (num) => {
    if (focus + num === -1) {
      return setFocus(0);
    }
    if (focus + num === popup?.content?.length) {
      return setFocus(popup?.content?.length - 1);
    }
    setFocus(focus + num);
  };

  useEffect(() => {
    if (component) {
      // When there is a popup that meet with conditions
      if (ipopups) {
        dispatch(setRecPopup(ipopups[0]));
        return;
      }
      dispatch(
        setRecPopup(
          state.ipopups?.find(
            (popup) =>
              popup?._id === state?.defaultJson?.defaultReccomendation?._id
          )
        )
      );
    }
  }, []);

  if (!popup) return '';
  // recommendationPopup
  // setRecPopupActive

  return (
    <Container component={component}>
      <Wrapper>
        <i
          onClick={() => dispatch(setRecPopupActive(false))}
          className='fas fa-times'
        ></i>
        <div className='popup-control'>
          {popup?.content?.map((x, i) => (
            <div
              style={{
                gridTemplateColumns: i === 0 ? 'auto 1fr' : 'auto auto 1fr',
              }}
              className='circle-container'
            >
              <div
                id='popup-circle'
                onClick={() => setFocus(i)}
                className={i === focus ? 'control-active' : 'control-disable'}
                style={{
                  display: i === 0 ? 'none' : 'grid',
                  color: i - 1 < focus ? x.activeColor : activeColor,
                  backgroundColor:
                    i - 1 < focus ? activeColor : x.passiveColorBg,
                }}
              >
                {i}
              </div>

              <p
                style={{
                  color: i > focus ? 'gray' : activeColor,
                }}
                className='popup-stepName'
              >
                {x.stepName}
              </p>
              {i + 1 !== popup?.content?.length && (
                <div
                  className={`popup-line ${i > focus && 'popup-line-dot'}`}
                  style={{
                    backgroundColor:
                      i - 1 < focus ? activeColor : x.passiveColorBg,
                  }}
                ></div>
              )}
            </div>
          ))}
        </div>
        <div className='popup'>
          <div className='popup-content'>
            {popup?.content?.map((x, i) => (
              <div
                style={{ display: i === focus ? 'grid' : 'none' }}
                className='popup-content-container'
              >
                <p className='popup-content-title'>{x?.title}</p>
                <BlockContent
                  className='block-content'
                  blocks={x.content}
                  serializers={serializers}
                />
                {x?.image?.asset.url && (
                  <img
                    src={x?.image?.asset.url}
                    alt='img'
                    className='popup-content-img'
                  />
                )}
              </div>
            ))}
          </div>
          <div className='popup-buttons'>
            <div className='show-me'>
              <button
                style={{ display: focus === 0 ? 'none' : 'block' }}
                onClick={() => controlHandler(-1)}
              >
                {popup.prevButton}
              </button>
              {popup?.content?.find((x, i) => i === focus)?.showMeHowText && (
                <button
                  onClick={() => {
                    popupPrevious && popupPassed
                      ? dispatch(setRecPopup(popupPrevious))
                      : dispatch(
                          setRecPopup(
                            popup?.content?.find((x, i) => i === focus)
                              ?.showMeHowRef
                          )
                        );
                    setPopupPassed(!popupPassed);
                  }}
                  // style={{ opacity: focus === 0 ? 0 : 1 }}
                >
                  {popup?.content?.find((x, i) => i === focus)?.showMeHowText}
                </button>
              )}
            </div>
            {!hideButton && (
              <button
                style={{ background: activeColor }}
                className='popup-next'
                onClick={() => {
                  controlHandler(+1);
                  if (focus === popup?.content?.length - 1) {
                    dispatch(setRecPopupActive(false));
                  }
                }}
              >
                {popup?.content?.length - 1 === focus
                  ? popup.endButton
                  : popup.nextButton}
              </button>
            )}
            {/* <button onClick={() => dispatch(setRecPopupActive(false))}>
              {popup.closeButton}
            </button> */}
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default RecommendationPopup;
